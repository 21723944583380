<template>
  <div>
    <common-header></common-header>

    <div class="purchase-list">
      <div class="selection-box">
        <div class="selection-item">
          <label>开始时间</label>
          <div class="item" @click="openPicker('0')">
            <span>{{startTime}}</span>
            <i class="icon-black-arrow"></i>
          </div>
        </div>
        <div class="selection-item">
          <label>结束时间</label>
          <div class="item" @click="openPicker('1')">
            <span>{{endTime}}</span>
            <i class="icon-black-arrow"></i>
          </div>
        </div>
        <div class="more" @click="popupVisible = true">更多条件</div>
      </div>
      <div class="table-box">
        <table class="table" border="1">
          <tr>
            <th>商品名</th>
            <th>规格名</th>
            <th class="w">单位</th>
            <th class="w">数量</th>
            <th>供应商</th>
          </tr>
          <tr v-for="(item, index) in lists" :key="index">
            <td>{{item.commodityName}}</td>
            <td>{{item.specName}}</td>
            <td>{{item.minUnitName}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.supplierName}}</td>
          </tr>
        </table>
        <no-data v-if="nodata"></no-data>
      </div>
    </div>
    

    <mt-datetime-picker
      ref="picker"
      type="datetime"
      year-format="{value}"
      month-format="{value}"
      date-format="{value}"
      :endDate="endDate"
        @confirm="handleConfirm"
      v-model="timeStartValue">
    </mt-datetime-picker>
<!-- 
    <mt-datetime-picker
      ref="picker"
      type="datetime"
      year-format="{value}"
      month-format="{value}"
      date-format="{value}"
      :endDate="endDate"
        @confirm="handleConfirm"
      v-model="timeEndValue">
    </mt-datetime-picker> -->
    <mt-popup
      v-model="popupVisible"
      class="w100"
      position="right">
      <div class="popup-box" :style="isWeiXin() ? '' : 'padding-top:40px'">
        <div class="selection-pop" v-if="orderTypeList.length > 0">
          <div class="name" @click="openMore(0)">
            订单状态
            <div class="pop-more">
              <span class="sele-g" v-if="orderStatusActive > -1">{{orderTypeList[orderStatusActive].name}}</span>
              <span v-show="!openStatus[0]">展开</span><i class="icon-black-arrow"></i>
            </div>
          </div>
          <div class="selection-items" :class="openStatus[0] ? 'active' : ''">
            <div class="items" v-for="(item, index) in orderTypeList" :class="orderStatusActive == index ? 'active' : ''" :key="index" @click="clickOrderType(index, 'orderType')"><span>{{item.name}}</span></div>
          </div>
        </div>
        <div class="selection-pop">
          <div class="name" @click="openMore(1)">
            支付状态
            <div class="pop-more">
              <span class="sele-g" v-if="payStatusActive > -1">{{payStatusList[payStatusActive].name}}</span>
              <span v-show="!openStatus[1]">展开</span><i class="icon-black-arrow"></i>
            </div>
          </div>
          <div class="selection-items " :class="openStatus[1] ? 'active' : ''">
            <div class="items" v-for="(item, index) in payStatusList" :key="index" :class="payStatusActive == index ? 'active': ''" @click="clickOrderType(index, 'payType')"><span>{{item.name}}</span></div>
          </div>
        </div>
        <div class="selection-pop">
          <div class="name" @click="openMore(2)">
            商品分类
            <div class="pop-more">
              <span class="sele-g text1">{{categoryFirst.text}}</span>
              <span v-show="!openStatus[2]">展开</span><i class="icon-black-arrow"></i>
            </div>
          </div>
          <div class="selection-items" :class="openStatus[2] ? 'active' : ''">
            <div class="items" v-for="(item, index) in commodityDa" :class="item.choice ? 'active' : ''" :key="index" @click="clickMultiple(index,'commodityType')"><span>{{item.name}}</span></div>
          </div>
          <!-- <van-checkbox-group v-model="commodityType" direction="horizontal" class="checkbox-flex">
            <van-checkbox :name="item.name" class="check-item" v-for="(item, index) in commodityDa" :key="index">{{item.name}}</van-checkbox>
          </van-checkbox-group> -->
        </div>
        <div class="selection-pop">
          <div class="name" @click="openMore(3)">
            营业部
            <div class="pop-more">
              <span class="sele-g text1">{{departmentId.text}}</span>
              <span v-show="!openStatus[3]">展开</span><i class="icon-black-arrow"></i>
            </div>
          </div>
          <div class="selection-items" :class="openStatus[3] ? 'active' : ''">
            <div class="items" :class="item.choice ? 'active' : ''" v-for="(item, index) in departmentDa" :key="index" @click="clickMultiple(index,'departmentDa')"><span>{{item.name}}</span></div>
          </div>
        </div>
        <div class="selection-pop">
          <div class="name" @click="openMore(4)">
            供应商
            <div class="pop-more">
              <span class="sele-g text1">{{supplierId.text}}</span>
              <span v-show="!openStatus[4]">展开</span><i class="icon-black-arrow"></i>
            </div>
          </div>
          <div class="selection-items" :class="openStatus[4] ? 'active' : ''">
            <div class="items" :class="item.choice ? 'active' : ''" v-for="(item, index) in supplierDa" :key="index" @click="clickMultiple(index,'supplierDa')"><span>{{item.name}}</span></div>
          </div>
        </div>
        <div class="confirm">
          <span @click="sureConfirm">确认</span>
        </div>
      </div>
    </mt-popup>


  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { DateFormat, isWeiXin } from '@/utils/common';
export default {
  data() {
    this.isWeiXin = ()=> isWeiXin();
    return {
      nodata: false,
      orderTypeList: [
        {type:100, name:'已下单'},
        {type:200, name:'待发货'},
        {type:201, name:'已发货'},
        {type:202, name:'确认收货'},
        {type:300, name:'已取消'},
        {type:400, name:'已完成'},
        {type:500, name:'已退款'}
      ],
      payStatusList: [
        {type:0, name:'待支付'},
        {type:1, name:'支付成功'},
        {type:2, name:'支付失败'},
        {type:3, name:'支付中'},
        {type:4, name:'取消支付'},
      ],
      commodityType: [],
      orderStatus: '已发货',
      payStatus: '待支付',
      popupVisible: false,
      timeStartValue: new Date(),
      endDate: new Date(),
      startTime: (DateFormat(new Date())),
      endTime: (DateFormat(new Date())),
      lists: [],
      commodityDa: [],
      departmentDa: [],
      departmentType: [],
      supplierType: [],
      supplierDa: [],
      openStatus: [false,false,false,false,false],
      // 选择合集
      orderStatusActive: -1,
      payStatusActive: -1,
      commodityActive: [],
      // 商品分类选择id
      categoryFirst: {},
      // 营业部
      departmentId: {},
      // 供应商
      supplierId: {},
    }
  },
  mounted() {
    // this.getList({})
    // this.CommodityClass({
    //   level: 1,
    // }).then((res)=> {
    //   for (var i = 0; i < res.data.length; i++) {
    //     res.data[i].choice = false;
    //   }
    //   this.commodityDa = res.data;
    // })

    // this.getOrderDepartmentList().then((res)=> {
    //   for (var i = 0; i < res.data.length; i++) {
    //     res.data[i].choice = false;
    //   }
    //   this.departmentDa = res.data;
    // })

    // this.getOrderSupplierList().then((res)=> {
    //   for (var i = 0; i < res.data.length; i++) {
    //     res.data[i].choice = false;
    //   }
    //   this.supplierDa = res.data;
    // })
  },
  methods: {
    sureConfirm() {
      this.popupVisible = false;
      this.getList({})
    },
    clickMultiple(i, name){
      if (name == 'commodityType') {
        let {commodityDa} = this;
        commodityDa[i].choice = !commodityDa[i].choice;
        this.categoryFirst.ids = this.suiteChoice(commodityDa).ids;
        this.categoryFirst.text = this.suiteChoice(commodityDa).text;
      } else if (name == 'departmentDa') {
        let {departmentDa} = this;
        departmentDa[i].choice = !departmentDa[i].choice;
        this.departmentId.ids = this.suiteChoice(departmentDa).ids;
        this.departmentId.text = this.suiteChoice(departmentDa).text;
      } else if (name == 'supplierDa') {
        let { supplierDa } = this;
        supplierDa[i].choice = !supplierDa[i].choice;
        this.supplierId.ids = this.suiteChoice(supplierDa).ids;
        this.supplierId.text = this.suiteChoice(supplierDa).text;
      }
    },
    suiteChoice(ay) {
      let ids = [];
      let text = [];
      for (var i = 0; i < ay.length; i++) {
        if(ay[i].choice) {
          ids.push(ay[i].id);
          text.push(ay[i].name);
        }
      }
      ids = ids.join(',');
      text = text.join(',');
      return {ids, text};
    },
    clickOrderType(i, name) {
      if (name == 'orderType') {
        if (this.orderStatusActive == i) {
          this.orderStatusActive = -1;
        } else {
          this.orderStatusActive = i;
        }
      } else if (name == 'payType') {
        if (this.payStatusActive == i) {
          this.payStatusActive = -1;
        } else {
          this.payStatusActive = i;
        }
      }
    },
    openMore(i) {
      this.$set(this.openStatus,i,!this.openStatus[i]);
    },
    getList(obj) {
      this.$Indicator.open({
        text: '加载中...',
        spinnerType: 'snake',
      });
      this.getOrderPurchaseList({
        categoryFirst: this.categoryFirst.ids || '',
        createTimeE: obj.endTime || '',
        createTimeS: obj.startTime || '',
        departmentId: this.departmentId.ids || '',
        supplierId: this.supplierId.ids || '',
      }).then((res)=> {
        this.nodata = res.data.list.length > 0 ? false : true;
        this.lists = res.data.list;
        this.startTime = res.data.params.createTimeS;
        this.endTime = res.data.params.createTimeE;
        this.orderStatusActive = this.orderTypeList.findIndex((item, index)=> item.type == res.data.params.status);
        this.payStatusActive = this.payStatusList.findIndex((item, index)=> item.type == res.data.params.payStatus);
        this.$Indicator.close();
      })
    },
    ...mapActions('account', [
      'getOrderPurchaseList',
      'getOrderDepartmentList',
      'getOrderSupplierList',
    ]),
    ...mapActions('shopping', [
      'CommodityClass'
    ]),
    handleConfirm(e) {
      if (this.timeIndex == 0) {
        this.startTime = (DateFormat(e));
        this.getList({
          startTime: this.startTime,
          endTime: this.endTime,
        })
      } else {
        this.endTime = (DateFormat(e));
        this.getList({
          startTime: this.startTime,
          endTime: this.endTime,
        })
      }
    }, 
    openPicker(i) {
      if (i == 0) {
        this.timeStartValue = new Date(this.startTime);
      } else {
        this.timeStartValue = new Date(this.endTime);
      }
      this.timeIndex = i;
      this.$refs.picker.open();
    },
  },
}
</script>
<style lang='less' scoped>
.pop-title{

}
.selection-items{
  display: flex;
  flex-wrap: wrap;
  max-height: .76rem;
  overflow: hidden;
  transition: .3s all;
  &.active{
    max-height: 10rem;
  }
  .items{
    text-align: center;
    min-width: 33.333333%;
    padding: .1rem;
    &.active{
      span{
        border:rgb(255, 114, 65) 1px solid;
        background: rgba(255, 113, 65, 0.2);
        color: rgb(255, 114, 65);
      }
    }
    span{
      border: #f5f5f5 1px solid;
      font-size: .24rem;
      background: #f5f5f5;
      border-radius: .4rem;
      display: block;
      padding: .1rem .15rem;
      
    }
  }
}
.sele-g{
  color: rgb(255, 114, 65);
  margin-right: .15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1;
  text-align: right;
}
.confirm{
  background: #fff;
  box-shadow: 0 0 .1rem #ccc;
  span{
    background: rgb(255, 114, 65);
    color: #fff;
    width: 80%;
    display: inline-block;
    padding: .3rem 0;
    border-radius: .15rem;
  }
  padding: .2rem;
  line-height: 1;
  margin-top: .4rem;
  text-align: center;
  width: 100%;
  margin:1rem auto 0;
  position: fixed;
  bottom: 0;
  left: 0;
}
.checkbox-flex{
  display: block;
  overflow-x: scroll;
  padding: .2rem 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  &::-webkit-scrollbar{ // 直接复制黏贴到样式页.css，完美解决
    display: none;
    /* background-color:transparent; */
  }
  .check-item{
    display: inline-flex;
  }
}
.w100{
  width: 70%;
  height: 100%;
}

.selection-pop{
  font-size: .26rem;
  padding-top: .3rem;
  color: #333;
  padding: .2rem;
  border-bottom: .15rem solid #f5f5f5;
  .name{
    font-size: .26rem;
    margin-bottom: .2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    .pop-more{
      font-weight: normal;
      font-size: .20rem;
      display: flex;
      flex: 1;
      margin-left: .2rem;
      justify-content: flex-end;
      align-items: center;
      .icon-black-arrow{
        margin-left: .1rem;
      }
    }
  }
  .box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:1px solid #f5f5f5;
    padding: .2rem;
  }
}
.popup-box{
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: .2rem 0;
  overflow-y: scroll;
  padding-bottom: 2rem;
  box-sizing: border-box;
}
.more{
  text-align: center;
  font-size: .3rem;
  padding: .2rem 0;
  border: 1px solid #ccc;
  margin-top: .1rem;
  border-radius: 0.15rem;
}
.selection-item{
  display: flex;
  align-items: center;
  padding: .1rem 0;
  label{
    margin-right: .2rem;
    font-size: .3rem;
  }
}
.table-box{
  background: #fff;
  padding: .2rem;
  .table{
    border-radius: .15rem;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    color: #333;
    border-color: #ccc;
    .w{
      width: 1rem;
    }
    th,td{
      padding: .1rem 0;
    }
    th{
      font-size: .28rem;
    }
    td{
      font-size: .24rem;
      padding: .1rem;
    }
  }
}
.purchase-list{
  padding: .2rem;
}
.selection-box{
  padding: .2rem .2rem .2rem;
  background: #fff;
  border-radius: .15rem;
  .item{
    border: 1px solid #ccc;
    padding: .2rem;
    flex: 1;
    border-radius: .15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .28rem;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>